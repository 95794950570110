import {ApolloClient, ApolloProvider, InMemoryCache} from '@apollo/client';
import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Shopify from '../functions/shopify';
import ConfirmData from '../steps/confirmData';
import ContactData from '../steps/contactData';
import Experience from '../steps/experience';
import HowDetermined from '../steps/howDetermined';
import IntermediateStepExperience from '../steps/intermediateStepExperience';
import PlanResult from '../steps/planResult';
import SelectAccessories from '../steps/selectAccessories';
import SelectClothes from '../steps/selectClothes';
import SignedUpAnotherStudio from '../steps/signedUpAnotherStudio';
import SignedUpInAnotherStudio from '../steps/signedUpInAnotherStudio';
import Welcome from '../steps/welcome';
import Error from './error';
import GetAllStudios from './getAllStudios';

function App() {
	const allSteps = [
		//{ element: <Welcome />, title: 'Willkommen' },
		//{ element: <SignedUpAnotherStudio />, title: 'signedUpAnotherStudio' },
		//{ element: <Experience />, title: 'experience' },
		//{
		//    element: <IntermediateStepExperience />,
		//    title: 'intermediateStepExperience',
		//},
		//{ element: <HowDetermined />, title: 'howDetermined' },
		{
			element: <PlanResult />,
			title: 'planResult',
			optionalPath: '/', //'/:planID/',
		},

		{element: <ContactData />, title: 'personalData'},
		{element: <ConfirmData />, title: 'confirmData'},
		//{ element: <Experience />, title: 'payment' },
		//{ element: <Experience />, title: 'checkData' },

		//{ element: <IntermediateStepConclusionOfContract />, title: 'conclusionOfContract' },
		{element: <SelectClothes />, title: 'selectClothes'},
		{element: <SelectAccessories />, title: 'selectAccessories'},
		//{ element: <SelectBoni />, title: 'selectBoni' },
		//{ element: <OrderItems />, title: 'orderItems' },
	];

	const client = new ApolloClient({
		uri: 'https://viva-fitness-shop.myshopify.com/api/2022-04/graphql.json',
		cache: new InMemoryCache(),
		headers: {
			'X-Shopify-Storefront-Access-Token': '50f4ea397fd6c3aae26fd90021dcf73a',
		},
	});

	document.body.style.zoom = '85%';

	return (
		<ApolloProvider client={client}>
			<Shopify />
			<BrowserRouter>
				<Routes>
					{allSteps.map((step, index) => {
						return (
							<Route
								key={index}
								path={
									'/:roomID/' +
									(index === 0 ? '' : index) +
									(step.optionalPath ? step.optionalPath : '')
								}
								element={step.element}
							/>
						);
					})}
					<Route path="/getAllStudios" element={<GetAllStudios />} />
					<Route
						path="/:roomID/signedUp"
						element={<SignedUpInAnotherStudio />}
					/>
					<Route path="/*" element={<Error />} />
				</Routes>
			</BrowserRouter>
		</ApolloProvider>
	);
}

export default App;
