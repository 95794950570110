import { configureStore } from '@reduxjs/toolkit';
import shopSlice from './slices/shopSlice';
import userInputDataSlice from './slices/userInputDataSlice';

export default configureStore({
    reducer: {
        userInput: userInputDataSlice,
        shop: shopSlice,
    },
});
