import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import handleNextStep from '../functions/handleNextStep';
import { setUserData } from '../redux/slices/userInputDataSlice';
import Button from '../uiKit/button';
import Container from '../uiKit/container';
import Text from '../uiKit/text';
import dateFormat from 'dateformat';

function ContactData(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname.split('/');

    const dispatch = useDispatch();

    const userInfo = useSelector((state) => state.userInput.userData);

    const [firstname, setFirstName] = useState(userInfo.firstname);
    const [lastname, setLastname] = useState(userInfo.lastname);
    const [dateOfBirth, setDateOfBirth] = useState(userInfo.dateOfBirth);
    const [email, setEmail] = useState(userInfo.email);
    const [gender, setGender] = useState(userInfo.gender);
    const [telephone, setTelephone] = useState(userInfo.telephone_private);
    const [street, setStreet] = useState(userInfo.street);
    const [additional, setAdditional] = useState(userInfo.floor);
    const [houseNumber, setHouseNumber] = useState(userInfo.houseNumber);
    const [city, setCity] = useState(userInfo.city);
    const [zipCode, setZipCode] = useState(userInfo.zipCode);
    const [accountHolder, setAccountHolder] = useState(
        userInfo.bankAccount.accountHolder
    );
    const [iban, setIBAN] = useState(userInfo.bankAccount.iban);

    const [sepaText, setSepaText] = useState('');

    const [sepa, setSepa] = useState(false);

    const [error, setError] = useState('');

    function saveUserData() {
        let errorFieldString = '';

        if (firstname === '' || firstname.length < 2) {
            errorFieldString += 'Vorname, ';
        }

        if (lastname === '' || lastname.length < 2) {
            errorFieldString += 'Nachname, ';
        }

        if (dateOfBirth === '') {
            errorFieldString += 'Geburstag, ';
        }

        if (
            email === '' ||
            !email.match(
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/
            )
        ) {
            errorFieldString += 'E-Mail, ';
        }

        if (telephone === '') {
            errorFieldString += 'Telefon, ';
        }

        if (street === '') {
            errorFieldString += 'Straße, ';
        }

        if (houseNumber === '') {
            errorFieldString += 'Hausnummer, ';
        }

        if (zipCode === '' || !zipCode.match(/^[0-9]{5}$/)) {
            errorFieldString += 'Postleitzahl, ';
        }

        if (city === '' || city.length < 2) {
            errorFieldString += 'Stadt, ';
        }

        if (accountHolder === '' || accountHolder.length < 2) {
            errorFieldString += 'Kontoinhaber, ';
        }

        if (iban === '' || iban.replaceAll(' ', '').length != 22) {
            errorFieldString += 'IBAN, ';
        }

        if (sepa === false) {
            errorFieldString += 'Annehmen der SEPA-Lastschrift-Ermächtigung, ';
        }

        if (errorFieldString !== '') {
            setError(
                `Bitte fülle auch die folgenden Felder aus: ${errorFieldString}`
            );
            return;
        }

        dispatch(
            setUserData({
                'firstname': firstname,
                'lastname': lastname,
                'dateOfBirth': dateOfBirth,
                'email': email,
                'gender': gender,
                'telephone_private': telephone,
                'telephone_mobile': 'string',
                'street': street,
                'floor': additional,
                'language': 'german',
                'locale': 'de_DE',
                'houseNumber': houseNumber,
                'city': city,
                'zipCode': zipCode,
                'countryCode': 'DE',
                'bankAccount': {
                    'iban': iban.replaceAll(' ', ''),
                    'bic': 'string',
                    'accountHolder': accountHolder,
                },
            })
        );
        handleNextStep(navigate, path[2], path[1]);
    }

    useEffect(() => {
        axios
            .get(
                'https://viva-fitness.api.magicline.com/connect/v1/studio/' +
                    path[1] +
                    '/sepa/agreement'
            )
            .then((res) => {
                if (res.data) {
                    setSepaText(res.data);
                }
            })
            .catch(
                (e) => {} //console.log(e)
            );
    }, []);

    return (
        <Container styles="w-full h-full flex items-center justify-center flex-col ">
            <div className="w-3/4 md:w-2/3 xl:w-1/3">
                <Text>Persönliche Daten</Text>
                <div className="flex justify-between flex-wrap">
                    <div className="flex items-center justify-center w-full md:w-fit">
                        <label htmlFor="divers" className="mr-2">
                            <input
                                type={'radio'}
                                id="divers"
                                name="gender"
                                className="mr-1 active:text-primary"
                                checked={gender === 'UNISEX'}
                                onChange={() => setGender('UNISEX')}
                            />
                            Divers
                        </label>
                        <label htmlFor="male" className="mx-2">
                            <input
                                type={'radio'}
                                id="male"
                                name="gender"
                                className="mr-1"
                                checked={gender === 'MALE'}
                                onChange={() => setGender('MALE')}
                            />
                            Männlich
                        </label>
                        <label htmlFor="female" className="mx-2">
                            <input
                                type={'radio'}
                                id="female"
                                name="gender"
                                className="mr-1"
                                checked={gender === 'FEMALE'}
                                onChange={() => setGender('FEMALE')}
                            />
                            Weiblich
                        </label>
                    </div>
                    <label className="py-4 md:py-0 flex flex-col">
                        Geburtstag
                        <input
                            type="date"
                            placeholder="Geburstag"
                            className="bg-white bg-opacity-20 border-white border rounded-md px-2 py-1 my-2 ml-1"
                            onChange={(e) => setDateOfBirth(e.target.value)}
                            value={dateOfBirth}
                            min="1900-01-01"
                            // set max date to today - 18 years
                            max={dateFormat(
                                new Date(
                                    new Date().setFullYear(
                                        new Date().getFullYear() - 18
                                    )
                                ),
                                'yyyy-mm-dd'
                            )}
                        />
                    </label>
                </div>
                <div className="flex flex-wrap">
                    <div className="w-full md:w-1/2 md:pr-2">
                        <input
                            type="text"
                            placeholder="Vorname"
                            className="w-full bg-white bg-opacity-20 border-white border rounded-md px-3 py-2 my-2"
                            onChange={(e) => setFirstName(e.target.value)}
                            value={firstname}
                        />
                    </div>
                    <div className="w-full md:w-1/2 md:pl-2">
                        <input
                            type="text"
                            placeholder="Nachname"
                            className="w-full bg-white bg-opacity-20 border-white border rounded-md px-3 py-2 my-2 "
                            onChange={(e) => setLastname(e.target.value)}
                            value={lastname}
                        />
                    </div>
                </div>
                <div className="flex flex-wrap">
                    <div className="w-full md:w-1/2 md:pr-2">
                        <input
                            type="text"
                            placeholder="Email"
                            className="w-full bg-white bg-opacity-20 border-white border rounded-md px-3 py-2 my-2  "
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                        />
                    </div>
                    <div className="w-full md:w-1/2 md:pl-2">
                        <input
                            type="text"
                            placeholder="Telefon"
                            className="w-full bg-white bg-opacity-20 border-white border rounded-md px-3 py-2 my-2  "
                            onChange={(e) => setTelephone(e.target.value)}
                            value={telephone}
                        />
                    </div>
                </div>
                <div className="my-6">
                    <Text>Anschrift</Text>
                    <div className="flex">
                        <div className="w-4/6 md:w-4/5 pr-2">
                            <input
                                type="text"
                                placeholder="Straße"
                                className="w-full bg-white bg-opacity-20 border-white border rounded-md px-3 py-2 my-2  "
                                onChange={(e) => setStreet(e.target.value)}
                                value={street}
                            />
                        </div>
                        <div className="w-2/6 md:w-1/5 pl-2">
                            <input
                                type="text"
                                placeholder="Nummer"
                                className="w-full bg-white bg-opacity-20 border-white border rounded-md px-3 py-2 my-2  "
                                onChange={(e) => setHouseNumber(e.target.value)}
                                value={houseNumber}
                            />
                        </div>
                    </div>
                    <div>
                        <input
                            type="text"
                            placeholder="Zusätzliche Infos (Optional)"
                            className="w-full bg-white bg-opacity-20 border-white border rounded-md px-3 py-2 my-2"
                            onChange={(e) => setAdditional(e.target.value)}
                            value={additional}
                        />
                    </div>
                    <div className="flex flex-wrap">
                        <div className="w-full md:w-1/5 md:pr-2">
                            <input
                                type="text"
                                placeholder="PLZ"
                                className="w-full bg-white bg-opacity-20 border-white border rounded-md px-3 py-2 my-2  "
                                onChange={(e) => setZipCode(e.target.value)}
                                value={zipCode}
                            />
                        </div>
                        <div className="w-full md:w-4/5 md:pl-2">
                            <input
                                type="text"
                                placeholder="Ort"
                                className="w-full bg-white bg-opacity-20 border-white border rounded-md px-3 py-2 my-2 "
                                onChange={(e) => setCity(e.target.value)}
                                value={city}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <Text>Bankdaten</Text>
                    <div>
                        <input
                            type="text"
                            placeholder="Kontoinhaber"
                            className="w-full bg-white bg-opacity-20 border-white border rounded-md px-3 py-2 my-2"
                            onChange={(e) => setAccountHolder(e.target.value)}
                            value={accountHolder}
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            placeholder="IBAN"
                            className="w-full bg-white bg-opacity-20 border-white border rounded-md px-3 py-2 my-2"
                            onChange={(e) => setIBAN(e.target.value)}
                            value={iban}
                        />
                    </div>
                    <div className="mt-4">
                        <input
                            type={'checkbox'}
                            id="sepa_agreement"
                            className="mr-2 min-w-[20px] transform scale-150 md:scale-125"
                            checked={sepa}
                            onChange={(e) => setSepa(!sepa)}
                        />
                        <label htmlFor="sepa_agreement">
                            {sepaText.replaceAll('<br/>', '\n')}
                        </label>
                    </div>
                </div>
                {error !== '' && (
                    <div className="text-red-500 mt-6">{error}</div>
                )}
                <Button label="Weiter" onPress={() => saveUserData()} />
            </div>
        </Container>
    );
}

export default ContactData;
