import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

function Button(props) {
    return (
        <div className=" mt-8 w-fit ">
            <div
                className="bg-primary px-4 py-3 rounded-lg shadow-xl font-semibold w-fit text-2xl  hover:shadow-3xl hover:cursor-pointer hover:bg-primary-light active:bg-primary-dark transition-all"
                onClick={props.onPress}
            >
                {props.label ? props.label : 'LABEL'}
            </div>
        </div>
    );
}

Button.propTypes = {
    label: PropTypes.string,
    onPress: PropTypes.func,
    onEnter: PropTypes.func,
};

export default Button;
