import React, { useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import handleNextStep from '../functions/handleNextStep';
import Button from '../uiKit/button';
import Container from '../uiKit/container';
import Text from '../uiKit/text';
import { addItemsToOrder } from '../redux/slices/userInputDataSlice';
import { useDispatch } from 'react-redux';
import store from '../redux/store';
import Cart from '../uiKit/cart';

function SelectAccessories(props) {
    const products = useSelector((state) => state.shop.allAccessories);

    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname.split('/');

    const dispatch = useDispatch();

    const [buttonText, setButtonText] = useState('Zum Warenkorb');

    const userData = useSelector((state) => state.userInput.userData);

    const [addToCart, { data, loading, error }] = useMutation(gql`
        mutation checkoutCreate($input: CheckoutCreateInput!) {
            checkoutCreate(input: $input) {
                checkout {
                    id
                    webUrl
                }
                checkoutUserErrors {
                    code
                    field
                    message
                }
            }
        }
    `);

    async function createCheckout() {
        const cart = store.getState().userInput.itemsToOrder;

        let tempCart = [];

        cart.forEach((item) => {
            let tempItem = { ...item };

            delete tempItem.productData;

            tempCart.push(tempItem);
        });

        const gqlVars = {
            'input': {
                'buyerIdentity': {
                    'countryCode': 'DE',
                },
                'customAttributes': {
                    'key': 'shop',
                    'value': 'byFormular',
                },
                'email': userData.email,
                'lineItems': tempCart,
                'shippingAddress': {
                    'address1': userData.street + ' ' + userData.houseNumber,
                    'address2': userData.floor,
                    'city': userData.city,
                    'company': '',
                    'country': 'Germany',
                    'firstName': userData.firstname,
                    'lastName': userData.lastname,
                    'phone': userData.telephone_private,
                    'province': '',
                    'zip': userData.zipCode,
                },
            },
            'queueToken': '',
        };

        setButtonText('Warenkorb wird erstellt...');

        let res = await addToCart({ variables: gqlVars });

        let { data, errors } = res;

        var cartRes = data.checkoutCreate;

        if (cartRes.checkoutUserErrors.length > 0) {
            let errorMSG = 'Es sind folgende Fehler aufgetreten: ';
            cartRes.checkoutUserErrors.forEach((error, index) => {
                console.log(error.message);
                errorMSG += error.message;

                if (cartRes.checkoutUserErrors.length - 1 !== index) {
                    errorMSG += ', ';
                    return;
                }

                errorMSG += '.';
            });

            alert(errorMSG);

            setButtonText('Warenkorb konnte nicht erstellt werden');
            return;
        }

        if (data) {
            setButtonText('Zum Warenkorb');
            window.open(data?.checkoutCreate?.checkout?.webUrl, '_self');
        }
    }

    function addItemToCart(product) {
        dispatch(addItemsToOrder(product));
    }

    return (
        <Container styles="w-full h-full flex justify-center items-center flex-col">
            <div className="w-3/4 md:w-2/3">
                <Text>Unsere Empfehlungen:</Text>
                <div className="mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-5 ">
                    {products.map((item) => {
                        return <ProductCard key={item.id} {...item} />;
                    })}
                </div>

                <div className="flex justify-end">
                    <Button
                        label={buttonText}
                        onPress={async () => {
                            createCheckout();
                        }}
                    />
                </div>
            </div>
            <Cart />
        </Container>
    );

    function ProductCard(props) {
        const { productType, featuredImage, title, description, variants, id } =
            props;
        const [selectedSize, setSelectedSize] = useState(0);
        const [price, setPrice] = useState('Fehler');
        const [selectedVariant, setSelectedVariant] = useState('');

        const [infoText, setInfoText] = useState('');

        useEffect(() => {
            setPrice(variants.nodes[0].price);
            setSelectedVariant(variants.nodes[0].id);
        }, []);

        return (
            <div className="relative" key={id}>
                {!featuredImage && <div className="py-4"></div>}
                <img
                    className="aspect-square object-cover rounded-md"
                    src={featuredImage?.url}
                />
                <div className="flex justify-between my-6">
                    <div>
                        <div className="text-2xl">{title}</div>
                        <div className="line-clamp-3">{description}</div>
                    </div>
                    <div className="text-md font-medium">{price}€</div>
                </div>
                <div
                    className={
                        'flex flex-wrap ' +
                        (productType === 'variant' && '-mx-1 mb-5')
                    }
                >
                    {productType === 'variant' &&
                        variants.nodes.map(
                            (
                                { title, price, id, quantityAvailable },
                                index
                            ) => {
                                let style =
                                    'w-fit px-3 h-10 m-1 border-white border flex items-center justify-center cursor-pointer hover:bg-white hover:bg-opacity-20 transition-all rounded-sm ' +
                                    (selectedSize === index
                                        ? 'bg-white bg-opacity-20'
                                        : '');

                                if (quantityAvailable === 0) {
                                    style =
                                        'w-fit px-3 h-10 m-1 border-gray-600 text-gray-600 border flex items-center justify-center cursor-pointer rounded-sm';
                                }
                                return (
                                    <div
                                        className={style}
                                        key={id}
                                        onClick={() => {
                                            if (quantityAvailable > 0) {
                                                setSelectedSize(index);
                                                setPrice(price);
                                                setSelectedVariant(id);
                                                setInfoText('');
                                            }
                                        }}
                                    >
                                        {title}
                                    </div>
                                );
                            }
                        )}
                </div>
                <div
                    className="bg-primary flex items-center justify-center py-4 hover:bg-primary-light cursor-pointer rounded-md"
                    onClick={() => {
                        addItemToCart({
                            quantity: 1,
                            variantId: selectedVariant,
                            productData: {
                                featuredImage,
                                title,
                                description,
                                variants,
                                id,
                            },
                        });
                        setInfoText('Produkt wurde dem Warenkorb hinzugefügt');
                        setTimeout(() => {
                            setInfoText('');
                        }, 1000);
                    }}
                >
                    In den Warenkorb
                </div>
                <div
                    className={infoText !== '' ? 'visible' : 'hidden'}
                    key={22}
                >
                    {infoText}
                </div>
            </div>
        );
    }
}

export default SelectAccessories;
