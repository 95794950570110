import { createSlice } from '@reduxjs/toolkit';

export const counterSlice = createSlice({
    name: 'userInput',
    initialState: {
        userData: {
            'firstname': '',
            'lastname': '',
            'dateOfBirth': '',
            'email': '',
            'gender': 'UNISEX',
            'telephone_private': '',
            'telephone_mobile': '',
            'street': '',
            'floor': '',
            'language': '',
            'locale': '',
            'houseNumber': '',
            'city': '',
            'zipCode': '',
            'countryCode': '',
            'bankAccount': {
                'iban': '',
                'accountHolder': '',
            },
        },
        itemsToOrder: [],
        selectedPlan: {},
    },
    reducers: {
        setUserData: (state, action) => {
            state.userData = action.payload;
        },
        addItemsToOrder: (state, action) => {
            let index = state.itemsToOrder.findIndex(
                (item) => item.variantId === action.payload.variantId
            );

            if (index === -1) {
                state.itemsToOrder.push(action.payload);
                return;
            }

            state.itemsToOrder[index].quantity += 1;
        },
        removeItemsFromOrder: (state, action) => {
            let index = state.itemsToOrder.findIndex(
                (item) => item.variantId === action.payload.variantId
            );

            if (index === -1) {
                return;
            }

            state.itemsToOrder.splice(index, 1);
        },
        setCertainLevel: (state, action) => {
            state.certainLevel = action.payload;
        },
        setSelectedPlan: (state, action) => {
            state.selectedPlan = action.payload;
        },
    },
});

export const {
    setUserData,
    addItemsToOrder,
    setCertainLevel,
    setSelectedPlan,
    removeItemsFromOrder,
} = counterSlice.actions;

export const selectCart = (state) => state.userInput.itemsToOrder;

export default counterSlice.reducer;
