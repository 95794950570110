import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    removeItemsFromOrder,
    selectCart,
} from '../redux/slices/userInputDataSlice';

import CartIcon from '../assets/cart.png';
import DeleteIcon from '../assets/delete.png';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

function Cart(props) {
    const cart = useSelector(selectCart);

    const [numberOfItems, setNumberOfItems] = useState(0);

    const [isCartOpen, setIsCartOpen] = useState(false);

    let dispatch = useDispatch();

    useEffect(() => {
        var temp = 0;

        cart.forEach((item) => {
            temp += item.quantity;
        });

        setNumberOfItems(temp);
    }, [cart]);

    return (
        <div className="top-8 right-8 fixed">
            <div
                className="relative flex flex-col items-end hover:opacity-80 active:opacity-60 transition-all"
                onClick={() => setIsCartOpen(!isCartOpen)}
            >
                <div className="bg-white cursor-pointer bg-opacity-25 w-14 h-14 rounded-full flex items-center justify-center">
                    <img src={CartIcon} className="w-8 h-8" />
                </div>
                <div className="absolute -bottom-2 -right-2 w-6 h-6 bg-primary flex items-center justify-center rounded-full cursor-pointer">
                    {numberOfItems}
                </div>
            </div>
            {isCartOpen && (
                <div className="bg-background rounded-lg mt-4 ">
                    <div className="bg-white bg-opacity-10 z-50 rounded-lg">
                        {cart.map((props) => {
                            let { variantId, productData, quantity } = props;

                            let variantData = productData.variants.nodes.find(
                                (item) => item.id === variantId
                            );

                            return (
                                <div
                                    key={variantId}
                                    className="flex justify-between gap-8 py-3 px-4 border-b border-white border-opacity-25 last:border-0 z-50"
                                >
                                    <div className="flex items-center gap-2">
                                        <div>{quantity + 'x'}</div>
                                        <div>
                                            <div>{productData.title}</div>
                                            {productData.variants.nodes.length >
                                                1 && (
                                                <div className="font-thin text-sm">
                                                    {variantData.title}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="flex items-center gap-2">
                                        <div>
                                            {(variantData.price * quantity)
                                                .toFixed(2)
                                                .replace('.', ',')}{' '}
                                            €
                                        </div>
                                        <div
                                            onClick={() =>
                                                dispatch(
                                                    removeItemsFromOrder(props)
                                                )
                                            }
                                            className="cursor-pointer"
                                        >
                                            <img
                                                src={DeleteIcon}
                                                className="h-6"
                                            />
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Cart;
