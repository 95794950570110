import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import handleNextStep from '../functions/handleNextStep';
import {setSelectedPlan} from '../redux/slices/userInputDataSlice';
import Button from '../uiKit/button';
import Container from '../uiKit/container';
import Text from '../uiKit/text';

function PlanResult(props) {
	const specialOfferID = null; // 1275154482

	const navigate = useNavigate();
	const location = useLocation();
	const path = location.pathname.split('/');

	const [plan, setPlan] = useState([]);
	const [selectedPlan, setSelectedPlanData] = useState(null);
	const [otherPlans, setOtherPlans] = useState([]);
	const recommendedPlan = parseInt(path[3] || 1261361520);

	const [recommendPlanData, setRecommendPlanData] = useState(null);

	const [specialOffer, setSpecialOffer] = useState();

	const dispatch = useDispatch();

	function translateMonth(month) {
		switch (month) {
			case 'DAY':
				return 'Tag';

			case 'WEEK':
				return 'Woche/n';

			case 'MONTH':
				return 'Monat/e';

			case 'YEAR':
				return 'Jahr';

			default:
				return 'Fehler!';
		}
	}

	useEffect(() => {
		if (plan.length < 1) {
			axios
				.get(
					'https://viva-fitness.api.magicline.com/connect/v1/rate-bundle?studioId=' +
						path[1]
				)
				.then((res) => {
					if (res.data) {
						// remove Vorauszahlungabo

						var temp = res.data;

						var key1 = temp.findIndex((element) => element.id === 1282187190);
						if (key1 !== -1) {
							temp.splice(key1, 1);
						}

						var key2 = temp.findIndex((element) => element.id === 1282187191);

						if (key2 !== -1) {
							temp.splice(key2, 1);
						}

						var keyTemp = temp.findIndex(
							(element) => element.id === 1282187530
						);

						if (keyTemp !== -1) {
							temp.splice(keyTemp, 1);
						}

						// remove special offer if exists and set special offer
						var key3 = temp.findIndex(
							(element) => element.id === specialOfferID
						);

						if (key3 !== -1) {
							setSpecialOffer(temp[key3]);
							setSelectedPlanData(temp[key3]);
							temp.splice(key3, 1);
						}

						let tempPlan = temp.filter(
							(item) => item.id === recommendedPlan
						)[0];

						setPlan(temp);
						setRecommendPlanData(tempPlan);

						if (key3 === -1) {
							setSelectedPlanData(tempPlan);
						}
					}
				})
				.catch((e) => {
					//console.log(e.response.data.message);
				});
		}
	}, [path]);

	useEffect(() => {
		if (recommendPlanData) {
			let temp = plan.filter((item) => item.id !== recommendPlanData.id);
			setOtherPlans(temp);
		}
	}, [recommendPlanData]);

	return (
		<Container styles="w-full h-full flex flex-col items-center">
			<Text>Deine perfekte Mitgliedschaft:</Text>

			<div className="mt-6">
				<div className="w-screen flex items-center justify-center mt-6">
					<div className="flex flex-col items-center">
						<div className="bg-background-light md:px-7 py-5 rounded-2xl flex flex-col items-center -mr-5">
							<div className="text-primary font-semibold md:text-xl text-base mb-3">
								{otherPlans[0] && otherPlans[0].name}
							</div>
							<div className="flex px-7">
								<div className="text-black font-extrabold md:text-5xl text-3xl">
									{otherPlans[0] &&
										otherPlans[0]?.terms[0].price
											.toFixed(2)
											.toString()
											.split('.')[0] + ','}
								</div>
								<div>
									<div className="text-black font-extrabold md:text-xl text-base">
										{otherPlans[0] &&
											otherPlans[0]?.terms[0]?.price
												.toFixed(2)
												.toString()
												.split('.')[1] + '€'}
									</div>
									<div className="text-black font-thin text-xs ml-1">
										{otherPlans[0] &&
											'/ ' +
												translateMonth(
													otherPlans[0]?.terms[0]?.paymentFrequencyUnit
												)}
									</div>
								</div>
							</div>
							<div className="font-light md:text-lg text-base text-primary my-1">
								{otherPlans[0] &&
									otherPlans[0]?.terms[0]?.termValueWithoutBonusPeriod +
										' ' +
										translateMonth(otherPlans[0]?.terms[0]?.termUnit)}
							</div>
						</div>
						<div
							className="mt-8 z-10 cursor-pointer"
							onClick={() => setSelectedPlanData(otherPlans[0])}
						>
							<span
								className={
									'material-icons p-2 rounded-full border ' +
									(selectedPlan === otherPlans[0]
										? 'text-white border-primary bg-primary'
										: 'text-gray-500 border-gray-500')
								}
							>
								check
							</span>
						</div>
					</div>
					<div className="flex flex-col items-center">
						<div className="bg-background-light overflow-hidden pt-5 rounded-2xl flex flex-col items-center transform scale-110 shadow-2xl shadow-black">
							<div className="text-primary font-semibold md:text-xl text-base mb-3 md:px-7">
								{recommendPlanData && recommendPlanData.name}
							</div>
							<div className="flex px-7">
								<div className="text-black font-extrabold md:text-5xl text-3xl">
									{recommendPlanData &&
										recommendPlanData?.terms[0].price
											.toFixed(2)
											.toString()
											.split('.')[0] + ','}
								</div>
								<div className="">
									<div className="text-black font-extrabold md:text-xl text-base">
										{recommendPlanData &&
											recommendPlanData?.terms[0]?.price
												.toFixed(2)
												.toString()
												.split('.')[1] + '€'}
									</div>
									<div className="text-black font-thin text-xs ml-1">
										{recommendPlanData &&
											'/ ' +
												translateMonth(
													recommendPlanData?.terms[0]?.paymentFrequencyUnit
												)}
									</div>
								</div>
							</div>
							<div className="font-light md:text-lg text-base text-primary my-1">
								{recommendPlanData &&
									recommendPlanData?.terms[0]?.termValueWithoutBonusPeriod +
										' ' +
										translateMonth(recommendPlanData?.terms[0]?.termUnit)}
							</div>
							{!specialOffer && (
								<div className="pb-2 pt-2 mt-1 bg-primary w-full text-center text-sm">
									Empfohlen
								</div>
							)}
						</div>

						<div
							className="mt-8 z-10  cursor-pointer"
							onClick={() => setSelectedPlanData(recommendPlanData)}
						>
							<span
								className={
									'material-icons p-2 rounded-full border ' +
									(selectedPlan === recommendPlanData
										? 'text-white border-primary bg-primary'
										: 'text-gray-500 border-gray-500')
								}
							>
								check
							</span>
						</div>
					</div>
					<div className="flex flex-col items-center">
						<div className="bg-background-light md:px-7 py-5 rounded-2xl flex flex-col items-center -ml-5">
							<div className="text-primary font-semibold md:text-xl text-base  mb-3">
								{otherPlans[1] && otherPlans[1].name}
							</div>
							<div className="flex px-7">
								<div className="text-black font-extrabold md:text-5xl text-3xl">
									{otherPlans[1] &&
										otherPlans[1]?.terms[0].price
											.toFixed(2)
											.toString()
											.split('.')[0] + ','}
								</div>
								<div>
									<div className="text-black font-extrabold md:text-xl text-base">
										{otherPlans[1] &&
											otherPlans[1]?.terms[0]?.price
												.toFixed(2)
												.toString()
												.split('.')[1] + '€'}
									</div>
									<div className="text-black font-thin text-xs ml-1">
										{otherPlans[1] &&
											'/ ' +
												translateMonth(
													otherPlans[1]?.terms[0]?.paymentFrequencyUnit
												)}
									</div>
								</div>
							</div>
							<div className="font-light md:text-lg text-base text-primary my-1">
								{otherPlans[1] &&
									otherPlans[1]?.terms[0]?.termValueWithoutBonusPeriod +
										' ' +
										translateMonth(otherPlans[1]?.terms[0]?.termUnit)}
							</div>
						</div>
						<div
							className="mt-8 z-10  cursor-pointer"
							onClick={() => setSelectedPlanData(otherPlans[1])}
						>
							<span
								className={
									'material-icons p-2 rounded-full border ' +
									(selectedPlan === otherPlans[1]
										? 'text-white border-primary bg-primary'
										: 'text-gray-500 border-gray-500')
								}
							>
								check
							</span>
						</div>
					</div>
				</div>
				<div>
					{specialOffer && (
						<div className="flex w-full flex-col items-center mt-6">
							<Text>!! Sonderangebot !!</Text>
							<div className="mt-1 mb-3">
								Trainiere die ersten 90 Tage für 90€
							</div>
							<div className="bg-background-light overflow-hidden pt-5 mt-6 rounded-2xl flex flex-col items-center transform scale-110 shadow-2xl shadow-black">
								<div className="text-primary font-semibold md:text-xl text-base mb-3 md:px-7">
									{specialOffer && specialOffer.name}
								</div>
								<div className="flex px-7">
									<div className="text-black font-extrabold md:text-5xl text-3xl">
										{specialOffer &&
											specialOffer?.terms[0].price
												.toFixed(2)
												.toString()
												.split('.')[0] + ','}
									</div>
									<div className="">
										<div className="text-black font-extrabold md:text-xl text-base">
											{specialOffer &&
												specialOffer?.terms[0]?.price
													.toFixed(2)
													.toString()
													.split('.')[1] + '€'}
										</div>
										<div className="text-black font-thin text-xs ml-1">
											{specialOffer &&
												'/ ' +
													translateMonth(
														specialOffer?.terms[0]?.paymentFrequencyUnit
													)}
										</div>
									</div>
								</div>
								<div className="font-light md:text-lg text-base text-primary mt-1">
									{specialOffer &&
										specialOffer?.terms[0]?.termValueWithoutBonusPeriod +
											' ' +
											translateMonth(specialOffer?.terms[0]?.termUnit)}
								</div>
								<div className="mb-2 text-gray-500 font-thin">
									49,90€ ab dem 4. Monat
								</div>

								<div className="pb-2 pt-2 mt-1 bg-primary w-full text-center text-sm">
									Empfohlen
								</div>
							</div>

							<div
								className="mt-8 z-10  cursor-pointer"
								onClick={() => setSelectedPlanData(specialOffer)}
							>
								<span
									className={
										'material-icons p-2 rounded-full border ' +
										(selectedPlan === specialOffer
											? 'text-white border-primary bg-primary'
											: 'text-gray-500 border-gray-500')
									}
								>
									check
								</span>
							</div>
						</div>
					)}
				</div>
			</div>
			{/*<div className="mt-12">
				<Text>Bei Onlineanmeldung 1 Monat gratis bei jedem Tarif</Text>
			</div>*/}
			<div className="mt-0">
				<Button
					label="Mitgliedschaft wählen"
					onPress={() => {
						//console.log(selectedPlan);
						dispatch(setSelectedPlan(selectedPlan));
						handleNextStep(navigate, 0, path[1]);
					}}
				/>
			</div>
		</Container>
	);
}

export default PlanResult;
