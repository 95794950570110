import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Container from '../uiKit/container';

function GetAllStudios(props) {
    const [allStudios, setAllStudios] = useState([]);

    useEffect(async () => {
        const studios = await axios.get(
            'https://viva-fitness.api.magicline.com/connect/v1/studio'
        );
        if (studios.data) {
            setAllStudios(
                studios.data.map((studio) => ({
                    name: studio.studioName,
                    id: studio.id,
                }))
            );
        }
    }, []);

    return (
        <Container styles="w-full min-h-full self-start">
            <div className="w-full">
                <h1 className="font-bold text-2xl mb-8">Alle Studios + ID</h1>
                {allStudios.length > 1 ? (
                    allStudios.map((item, index) => {
                        return (
                            <div
                                key={item.id}
                                className="px-4 py-3 grid grid-cols-3 gap-4 border-b border-b-white border-opacity-30 hover:bg-white hover:bg-opacity-10 transition-all"
                            >
                                <div>{item.name}</div>
                                <div className="flex items-center justify-center">
                                    =>
                                </div>
                                <div
                                    className="flex items-center justify-end hover:bg-white hover:bg-opacity-20 w-min justify-self-end p-2 rounded-lg"
                                    onClick={() => {
                                        navigator.clipboard.writeText(item.id);
                                    }}
                                >
                                    {item.id}
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <div>Es wurden keine Studios gefunden!</div>
                )}
            </div>
        </Container>
    );
}

export default GetAllStudios;
