import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import handleNextStep from '../functions/handleNextStep';
import Button from '../uiKit/button';
import Container from '../uiKit/container';
import Text from '../uiKit/text';
import {addItemsToOrder, selectCart} from '../redux/slices/userInputDataSlice';
import {useDispatch} from 'react-redux';
import store from '../redux/store';
import Cart from '../uiKit/cart';

function SelectClothes(props) {
	const clothes = useSelector((state) => state.shop.allClothes);

	const navigate = useNavigate();
	const location = useLocation();
	const path = location.pathname.split('/');

	const dispatch = useDispatch();

	function addItemToCart(product) {
		dispatch(addItemsToOrder(product));
	}

	useEffect(() => {
		addScript();
	}, []);

	function addScript() {
		const script = document.createElement('script');
		script.innerHTML = `!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '479251537580058');
        fbq('track', 'PageView');`;
		document.body.appendChild(script);
	}

	return (
		<Container styles="w-full h-full flex justify-center items-center flex-col">
			<Cart />
			<noscript>
				<img
					height="1"
					width="1"
					style={{display: 'none'}}
					src="https://www.facebook.com/tr?id=479251537580058&ev=PageView&noscript=1"
					alt="Produkt-Foto"
				/>
			</noscript>
			<div className="w-3/4 md:w-2/3">
				<Text>Deine Anmeldung war erfolgreich!</Text>
				<div>Dürfen wir dir noch ein paar Kleinigkeiten empfehlen?</div>
				<div className="mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
					{clothes.map((item) => {
						return <ProductCard {...item} key={item.id} />;
					})}
				</div>
				<div className="flex justify-end">
					<Button
						label="Weiter"
						onPress={async () => {
							handleNextStep(navigate, path[2], path[1]);
						}}
					/>
				</div>
			</div>
		</Container>
	);

	function ProductCard({
		productType,
		featuredImage,
		title,
		description,
		variants,
		id,
	}) {
		const [selectedSize, setSelectedSize] = useState(0);
		const [price, setPrice] = useState('Fehler');
		const [selectedVariant, setSelectedVariant] = useState('');

		const [infoText, setInfoText] = useState('');

		useEffect(() => {
			setPrice(variants.nodes[0].price);
			setSelectedVariant(variants.nodes[0].id);
		}, []);

		return (
			<div className=" relative" key={id}>
				<img
					className="aspect-square object-cover rounded-md"
					src={featuredImage?.url}
				/>
				<div className="flex justify-between my-6">
					<div>
						<div className="text-2xl">{title}</div>
						<div className="line-clamp-3">{description}</div>
					</div>
					<div className="text-md font-medium">{price}€</div>
				</div>
				<div className="flex flex-wrap -mx-1 mb-5">
					{productType === 'variant' &&
						variants.nodes.map(
							({title, price, id, quantityAvailable}, index) => {
								let style =
									'w-fit px-3 h-10 m-1 border-white border flex items-center justify-center cursor-pointer hover:bg-white hover:bg-opacity-20 transition-all rounded-sm ' +
									(selectedSize === index ? 'bg-white bg-opacity-20' : '');

								if (quantityAvailable === 0) {
									style =
										'w-fit px-3 h-10 m-1 border-gray-400 bg-opacity-20 text-gray-400 border flex items-center justify-center cursor-pointer rounded-sm';
								}
								return (
									<div
										className={style}
										key={id}
										onClick={() => {
											if (quantityAvailable > 0) {
												setSelectedSize(index);
												setPrice(price);
												setSelectedVariant(id);
												setInfoText('');
											}
										}}
									>
										{title}
									</div>
								);
							}
						)}
				</div>
				<div
					className="bg-primary flex items-center justify-center py-4 hover:bg-primary-light cursor-pointer rounded-md"
					onClick={() => {
						addItemToCart({
							quantity: 1,
							variantId: selectedVariant,
							productData: {
								featuredImage,
								title,
								description,
								variants,
								id,
							},
						});
						setInfoText('Produkt wurde dem Warenkorb hinzugefügt');
						setTimeout(() => {
							setInfoText('');
						}, 1000);
					}}
				>
					In den Warenkorb
				</div>
				<div
					className={infoText !== '' ? 'visible' : 'hidden'}
					key={id + 'info'}
				>
					{infoText}
				</div>
			</div>
		);
	}
}

export default SelectClothes;
