import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import handleNextStep from '../functions/handleNextStep';
import Button from '../uiKit/button';
import Container from '../uiKit/container';
import Text from '../uiKit/text';
import dateFormat from 'dateformat';
import SignaturePad from 'react-signature-canvas';
import axios from 'axios';
import {ReactSketchCanvas} from 'react-sketch-canvas';

function ConfirmData(props) {
	const navigate = useNavigate();
	const location = useLocation();
	const path = location.pathname.split('/');

	const userData = useSelector((state) => state.userInput.userData);

	const planData = useSelector((state) => state.userInput.selectedPlan);

	const [sepaText, setSepaText] = useState('');
	const [checkbox, setCheckbox] = useState(false);
	const [referalCode, setReferalCode] = useState('');

	useEffect(() => {
		axios
			.get(
				'https://viva-fitness.api.magicline.com/connect/v1/studio/' +
					path[1] +
					'/sepa/agreement'
			)
			.then((res) => {
				if (res.data) {
					setSepaText(res.data);
				}
			})
			.catch(
				(e) => {} //console.log(e)
			);
	}, []);

	async function postContract() {
		if (checkbox) {
			try {
				await axios.post(
					'https://viva-fitness.api.magicline.com/connect/v1/rate-bundle',
					{
						'studioId': path[1],
						'contract': {
							'rateBundleTermId': planData.terms[0].id,
							'startDate': planData.terms[0].defaultContractStartDate,
							'preuseDate': planData.terms[0].contractStartDateOfUse,
						},
						'customer': {
							'firstname': userData.firstname,
							'lastname': userData.lastname,
							'dateOfBirth': userData.dateOfBirth,
							'email': userData.email,
							'gender': userData.gender,
							'telephone_private': userData.telephone_private,
							'street': userData.street,
							'additionalAddressInformation': userData.floor,
							'locale': 'de_DE',
							'houseNumber': userData.houseNumber,
							'city': userData.city,
							'zipCode': userData.zipCode,
							'countryCode': 'DE',
							'paymentChoice': 'DIRECT_DEBIT',
							'bankAccount': {
								'iban': userData.bankAccount.iban,
								'accountHolder': userData.bankAccount.accountHolder,
							},
							'referralCode': referalCode,
							'studioCountryCode': 'DE',
							'publicApiGender': userData.gender,
						},
					}
				);
				//handleNextStep(navigate, path[2], path[1]);

				window.location.href =
					'https://www.vivafitness.de/vielen-dank-viva-fitness';
			} catch (error) {
				console.log(error);
				alert(
					"Etwas funktioniert nicht? Achte darauf, dass du nur gültige Zeichen verwendest: A-Z a-z 0-9 / - ? ( ) . , ' +."
				);
			}
		}
	}

	function getRecurringText(unit) {
		switch (unit) {
			case 'DAY':
				return 'jeden Tag';

			case 'WEEK':
				return 'jede Woche';

			case 'MONTH':
				return 'jeden Monat';

			case 'YEAR':
				return 'jedes Jahr';

			default:
				return 'Fehler!';
		}
	}

	function translateMonth(month) {
		switch (month) {
			case 'DAY':
				return 'Tag';

			case 'WEEK':
				return 'Woche';

			case 'MONTH':
				return 'Monat';

			case 'YEAR':
				return 'Jahr';

			default:
				return 'Fehler!';
		}
	}

	return (
		<Container styles="w-full h-full flex items-center justify-center flex-col ">
			<div className="w-3/4 md:w-2/3">
				<Text>Daten überprüfen</Text>
				<div className="my-6 flex flex-wrap">
					<div className="my-2 w-1/2">
						<div className="text-primary-light font-medium ">Name:</div>
						<div>{userData.firstname + ' ' + userData.lastname}</div>
					</div>
					<div className="my-2 w-1/2">
						<div className="text-primary-light font-medium">Anschrift:</div>
						<div>
							{userData.street + ' ' + userData.houseNumber}
							<br />

							{userData.floor}
							{userData.floor && <br />}

							{userData.zipCode + ' ' + userData.city}
						</div>
					</div>
					<div className="my-2 w-1/2">
						<div className="text-primary-light font-medium">Geburtsdatum:</div>
						<div>
							{dateFormat(new Date(userData.dateOfBirth), 'dd.mm.yyyy')}
						</div>
					</div>
					<div className="my-2 w-1/2">
						<div className="text-primary-light font-medium">Kontaktdaten:</div>
						<div>
							{userData.email}
							<br />
							{userData.telephone_private}
						</div>
					</div>
					<div className="my-2 w-1/2">
						<div className="text-primary-light font-medium">Bankdaten:</div>
						<div>
							{userData.bankAccount.accountHolder}
							<br />
							{userData.bankAccount.iban}
						</div>
					</div>
				</div>
				<Text>Leistungsübersicht</Text>
				<div className="my-6 whitespace-pre-wrap grid grid-cols-2">
					{planData.modules.map((module) => {
						return <div key={module.id}>{'- ' + module.name}</div>;
					})}
					<div>{planData.subDescription}</div>
					<div>{planData.footnote}</div>
				</div>

				<div className="my-16">
					<Text>Code</Text>
					<br />
					Hast du noch einen Weiterempfehlungscode?
					<br />
					Dann löse ihn hier ein und sicher dir deinen Vorteil!
					<br />
					<br />
					<input
						type={'text'}
						className="bg-opacity-10 bg-white placeholder:text-gray-400 px-3 py-4 rounded-lg min-w-[225px]"
						placeholder="Weiterempfehlungscode"
						value={referalCode}
						onChange={(event) => setReferalCode(event.target.value)}
					/>
				</div>
				<Text>Vertragsdetails</Text>
				<div className="my-6">
					<div className="flex justify-between bg-white bg-opacity-10 py-2 px-3">
						<div>Laufzeit</div>
						<div>
							{planData.terms[0].termValue +
								(planData.id == 1292205580 || planData.id == 1292208640
									? 'Monate'
									: ' Monate')}
						</div>
					</div>

					<div className="flex justify-between bg-white bg-opacity-0 py-2 px-3">
						<div>Zahlweise</div>
						<div>
							{getRecurringText(planData.terms[0].paymentFrequencyUnit) +
								' ' +
								planData.terms[0].price.toFixed(2).replace('.', ',') +
								' €'}
						</div>
					</div>
					<div className="flex justify-between bg-white bg-opacity-10 py-2 px-3">
						<div>Kündigungsfrist</div>
						<div>
							{planData.terms[0].cancellationPeriod +
								' ' +
								translateMonth(planData.terms[0].cancellationPeriodUnit)}
						</div>
					</div>
					<div className="flex justify-between bg-white bg-opacity-0 py-2 px-3">
						<div>Verlängerung</div>
						<div>
							{planData.terms[0].extensionFixedTerm +
								' ' +
								translateMonth(planData.terms[0].extensionFixedTermUnit)}
						</div>
					</div>
				</div>
				<Text>Zusätzliche Kosten</Text>
				<div className="my-6">
					{planData.terms[0].flatFees.map((fee, i) => {
						let css = 'flex justify-between py-2 px-3';

						if (i % 2 === 0) {
							css += ' bg-white bg-opacity-10';
						}

						return (
							<div key={fee.identifier} className={css}>
								<div>
									{fee.paymentFrequency === 'einmalig'
										? 'einm.'
										: fee.paymentFrequency}
								</div>
								<div>{fee.name}</div>
								<div>{fee.price.toFixed(2).replace('.', ',') + ' €'}</div>
							</div>
						);
					})}
				</div>
				<Text>Rechtliches</Text>
				<div className="my-6">
					Deine Mitgliedschaft bei uns startet am{' '}
					{dateFormat(
						new Date(planData.terms[0].defaultContractStartDate),
						'dd.mm.yyyy'
					)}
					. Du darfst aber schon ab{' '}
					{dateFormat(
						new Date(planData.terms[0].contractStartDateOfUse),
						'dd.mm.yyyy'
					)}{' '}
					bei uns trainieren. Anteilige Tage bis zu deinem Vertragsbeginn werden
					dir kostenpflichtig berechnet.
				</div>
				<div className="my-6 whitespace-pre-wrap">
					{sepaText.replaceAll('<br/>', '\n')}
				</div>
				<div className="my-6">
					Fast geschafft! Um bei uns eine Mitgliedschaft online abschließen zu
					können, müssen auch die folgenden Punkte von dir akzeptiert werden
					<br />
					<br />
					<label className="flex items-center gap-6">
						<input
							type={'checkbox'}
							onChange={(event) => setCheckbox(event.target.checked)}
							value={checkbox}
							className="mr-1 min-w-[20px] transform scale-150 md:scale-125"
						/>
						<div>
							Ja, die abgesendeten Daten werden zum Zweck der Bearbeitung meiner
							Anfrage verarbeitet und dürfen auch zur Kontaktaufnahme via
							Telefon oder E-Mail verwendet werden. Weitere Informationen finde
							ich in der Datenschutzerklärung.
						</div>
					</label>
				</div>

				<Button label="Jetzt buchen" onPress={() => postContract()} />
			</div>
		</Container>
	);
}

export default ConfirmData;
