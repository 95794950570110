import React from 'react';
import PropTypes from 'prop-types';

function Text(props) {
    return (
        <div className="text-2xl font-light w-fit">
            {props.children ? props.children : 'LABEL'}
        </div>
    );
}

export default Text;
