import { createSlice } from '@reduxjs/toolkit';

export const shopSlice = createSlice({
    name: 'shop',
    initialState: {
        allAccessories: [],
        allClothes: [],
        selectedItems: [],
    },
    reducers: {
        setAccessories: (state, action) => {
            state.allAccessories = action.payload;
        },
        setClothes: (state, action) => {
            state.allClothes = action.payload;
        },
        addSelectedItem: (state, action) => {
            state.selectedItems.push(action.payload);
        },
        removeSelectedItem: (state, action) => {
            state.selectedItems = state.selectedItems.filter(
                (item) => item.id !== action.payload.id
            );
        },
    },
});

export const {
    setAccessories,
    setClothes,
    addSelectedItem,
    removeSelectedItem,
} = shopSlice.actions;

export default shopSlice.reducer;
