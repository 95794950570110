import { gql, useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setAccessories, setClothes } from '../redux/slices/shopSlice';

function Shopify(props) {
    const {
        loadingAccessories,
        error,
        data: accessories,
    } = useQuery(gql`
        {
            products(
                first: 10
                query: "tag:Merchandise AND available_for_sale:true"
            ) {
                edges {
                    node {
                        tags
                        title
                        id
                        description
                        totalInventory
                        featuredImage {
                            url
                        }
                        variants(first: 100) {
                            nodes {
                                title
                                price
                                quantityAvailable
                                image {
                                    url
                                }
                                id
                            }
                        }
                    }
                }
            }
        }
    `);

    const { data: clothes } = useQuery(gql`
        {
            products(first: 10, query: "tag:Sets AND available_for_sale:true") {
                edges {
                    node {
                        tags
                        title
                        id
                        description
                        totalInventory
                        featuredImage {
                            url
                        }
                        variants(first: 100) {
                            nodes {
                                title
                                price
                                quantityAvailable
                                image {
                                    url
                                }
                                id
                            }
                        }
                    }
                }
            }
        }
    `);

    const dispatch = useDispatch();

    useEffect(() => {
        if (accessories) {
            const products = accessories.products.edges.map(({ node }) => {
                var product = node;

                var productType = 'single';

                if (product.variants.nodes.length > 1) {
                    productType = 'variant';
                }

                return { productType, ...product };
            });

            dispatch(setAccessories(products));
        }
    }, [accessories]);

    useEffect(() => {
        if (clothes) {
            const products = clothes.products.edges.map(({ node }) => {
                var product = node;

                var productType = 'single';

                if (product.variants.nodes.length > 1) {
                    productType = 'variant';
                }

                return { productType, ...product };
            });

            dispatch(setClothes(products));
        }
    }, [clothes]);

    return <></>;
}

export default Shopify;
