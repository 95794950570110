import { gql, useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import Container from '../uiKit/container';
import Text from '../uiKit/text';

function Error(props) {
    return (
        <Container styles="w-full h-full">
            <Text>Leider ist ein Fehler aufgetreten</Text>
        </Container>
    );
}

export default Error;
