import React from 'react';
import Container from '../uiKit/container';

function SignedUpInAnotherStudio(props) {
    return (
        <Container styles="w-full h-full flex items-center justify-center flex-col ">
            <div className="text-lg w-2/4">
                Komm bitte mit deiner Kündigungsbestätigung zu uns in Studio und
                du darfst bis zum Ende des gekündigten Vertrags bei uns
                kostenlos trainieren.
                <br />
                Genauere Infos bekommst du bei uns im Studio.
            </div>
        </Container>
    );
}

export default SignedUpInAnotherStudio;
