import React from 'react';
import PropTypes from 'prop-types';

function Container(props) {
    return (
        <div
            className={
                'flex lg:px-24 md:px-12 px-6 py-10 bg-background ' +
                props.styles
            }
        >
            {props.children ? props.children : <></>}
        </div>
    );
}

Container.propTypes = {
    styles: PropTypes.string,
};

export default Container;
